<template>
  <div class="forgetPass">
    <AppHeader :type="1"></AppHeader>
    <div class="warp"
         style="min-height:calc(100% - 165px)">
      <div class="content">
        <img class="topImg"
             src="@/assets/forgetPass.png"
             alt="">
        <el-form class="form"
                 ref="form"
                 :rules="rules"
                 :model="form"
                 label-width="120px">
          <el-form-item label="注册邮箱：" prop="email">
            <el-input maxlength="50"
                      placeholder="请输入邮箱"
                      auto-complete="new-email"
                      v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item label="邮箱验证码：" prop="vertify">
            <el-input maxlength="6"
                      auto-complete="new-accounts"
                      placeholder="请输入验证码"
                      v-model="form.vertify">

            </el-input>
            <i v-show="!isInter"
               @click="getVertify"
               v-loading="butLoading"
               class="suf suffix">
              获取邮箱验证码
            </i>
            <i v-show="isInter"
               class="suf suffix2">
              ({{inter}}s)
            </i>
          </el-form-item>
          <el-form-item label="新密码：" prop="newPass">
            <el-input maxlength="64"
                      type="password"
                      auto-complete="new-password"
                      placeholder="8-16位，必须包含数字及字母，大小写均可，支持特殊字符"
                      v-model.trim="form.newPass"></el-input>
          </el-form-item>
          <el-form-item label="确认新密码：" prop="newPassComfirm">
            <el-input maxlength="64"
                      type="password"
                      auto-complete="new-passwords"
                      placeholder="确认密码"
                      v-model.trim="form.newPassComfirm"></el-input>
          </el-form-item>
        </el-form>
        <div class="footBtn">
          <el-button type="primary"
                     @click="onSubmit('form')">重置密码</el-button>
        </div>
      </div>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>
<script>
import AppHeader from '@/components/layer/AppHeader'
import AppFooter from '@/components/layer/AppFooter'
import { setInterval, clearInterval, setTimeout } from 'timers'
import { forgetpwd, sendCode, checkEmail } from '@/api/user'
import { sm3 } from '@/utils/sm3'

export default {
  components: {
    AppHeader,
    AppFooter
  },
  data() {
     var validatePass = (rule, value, callback) => {
        const reg = /^(?=.*[a-zA-Z])(?=.*\d)[^\s\n\t]{8,16}$/
        if (value === '') {
          callback(new Error('请输入密码'));
        } 
        if (!reg.test(value)) {
         callback(new Error('密码格式不正确'));
        } else {
          if (this.form.newPassComfirm !== '') {
            this.$refs.form.validateField('newPassComfirm');
          }
          callback();
        }
      }
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.form.newPass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
    return {
      form: {
        email: '',
        newPassComfirm: '',
        newPass: '',
        vertify: ''
      },
      inter: 60,
      isInter: false,
      loading: '',
      butLoading:false,
      rules: {
          email: [
             { required: true, message: '请输入邮箱地址', trigger: 'blur' },
             { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
          ],
          vertify:[{
             required: true, message: '邮箱验证码不能为空', trigger: 'blur'
          }],
          newPass: [
            { validator: validatePass, trigger: 'blur' , required: true,}
          ],
          newPassComfirm: [
            { validator: validatePass2, trigger: 'blur', required: true, }
          ],
        }
    }
  },
  methods: {
    validateEmail(value) {
      value =
          typeof value === 'undefined' || value === null
            ? ''
            : value.replace(/\s+/g, '')
      value = value.replace(/\s+/g, '')
      if (!value && value !== '0') {
        return false
      }
      const reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
      if (reg.test(value)) {
        return true
      }
      return false
    },
    validatePass(value) {
      if (!value && value !== '0') {
        return false
      }
      const reg = /^(?=.*[a-zA-Z])(?=.*\d)[^]{8,16}$/
      if (reg.test(value)) {
        return true
      }
      return false
    },
    checkEmail() {
      const param = {
        email: this.form.email
      }
      checkEmail(param).then((res) => {
        this.$message.closeAll()
        if (res.retCode === 0) {
          if (res.exist === 0) {
            this.$message.error('用户邮箱不存在')
          } else {
            const data = {
              email: this.form.email,
              newPwd: sm3(this.form.newPass),
              verifyCode: this.form.vertify
            }
            forgetpwd(data).then((res) => {
              this.$message.closeAll()
              if (res.retCode == 0) {
                this.$message({
                  message: '修改成功',
                  type: 'success',
                  duration: 2000
                })
                setTimeout(() => {
                  // 从官网进来的修改密码 成功之后进入到官网
                  if(location.href.indexOf("from=portal") != -1){
                      window.location.href = window.toolbox_redirectUrl.portal;
                  }else{
                     this.$router.push({ path: '/' })
                  }
                }, 2000)
              } else {
                this.$message.error(res.retMsg)
              }
            })
          }
        } else {
          this.$message.error(res.retMsg)
        }
      })
    },
    onSubmit(formName) {
      this.$message.closeAll()
       this.$refs[formName].validate((valid) => {
          if(valid){
             this.checkEmail()
          }else{
              console.log('error submit!!');
              return false
          }
       })
      // if (this.form.email.trim() == '') {
      //   this.$message.error('邮箱不能为空')
      // } else if (!this.validateEmail(this.form.email)) {
      //   this.$message.error('邮箱格式不正确')
      // } else if (this.form.newPass.trim() == '') {
      //   this.$message.error('密码不能为空')
      // } else if (!this.validatePass(this.form.newPass)) {
      //   this.$message.error('密码格式不正确')
      // } else if (this.form.newPassComfirm != this.form.newPass) {
      //   this.$message.error('两次输入的密码不一致')
      // } else if (this.form.vertify.trim() == '') {
      //   this.$message.error('验证码不能为空')
      // } else {
      //   this.checkEmail()
      // }
    },
    getVertify() {
      this.$message.closeAll()
      if (this.form.email == '') {
        this.$message.error('邮箱不能为空')
      } else if (!this.validateEmail(this.form.email)) {
        this.$message.error('邮箱格式不正确')
      } else {
        // this.loading = this.$loading({
        //   lock: true,
        //   text: 'Loading',
        //   spinner: 'el-icon-loading',
        //   background: 'rgba(0, 0, 0, 0.7)'
        // })
        const param = {
          email: this.form.email
        }
        if (this.butLoading) return
        this.butLoading = true
        checkEmail(param).then((res) => {
          this.$message.closeAll()
          // this.loading.close()
          if (res.retCode === 0) {
            if (res.exist === 0) {
              this.$message.error('用户邮箱不存在')
            } else {
              this.sendCode()
            }
          }
        }).catch(err=>{
          this.$message.error('网络错误')
          this.butLoading = false
        })
      }
    },
    sendCode() {
      const data = {
        email: this.form.email,
        type: 3
      }
      sendCode(data).then((res) => {
        // this.$message.closeAll()
        // this.loading.close()
        if (res.retCode == 0) {
          this.isInter = true
          const that = this
          this.$message.success('已成功发送验证码，请前往邮箱查看。')
          const inters = setInterval(() => {
            that.inter--
            if (that.inter <= 0) {
              clearInterval(inters)
              that.isInter = false
              that.inter = 60
            }
          }, 1000)
        } else {
          this.$message.error(res.retMsg)
        }
      }).finally(()=>{
        this.butLoading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .forgetPass {
    height: 100%;
    .content {
      .suf {
        position: absolute;
        right: 0;
      }
      .suffix {
        font-style: normal;
        margin-right: 20px;
        font-size: 14px;
        color: #244bb0;
        cursor: pointer;
      }
      .suffix2 {
        font-style: normal;
        margin-right: 20px;
        font-size: 14px;
        color: #b4b4b4;
      }
      ::v-deep .el-form-item {
        margin-bottom: 32px;
      }
      // /deep/ .el-form-item__label{
      //   width:150px!important;
      //   color:#333333!important;
      // }
      // /deep/ .el-form-item__content{
      //   margin-left:0!important;
      // }
      width: 1000px;
      height: 622px;
      padding-top: 85px;
      box-shadow: 0px 14px 13px 0px rgba(186, 191, 198, 0.1);
      margin: 0 auto;
      .topImg {
        width: 100%;
        height: 157px;
      }
      .form {
        width: 600px;
        text-align: center;
        margin: 50px auto;
      }
      .footBtn {
        width: 100%;
        text-align: center;
        button {
          width: 160px;
          height: 50px;
          font-size: 18px;
          color: #fff;
          background: linear-gradient(
            -90deg,
            rgba(49, 97, 187, 1) 0%,
            rgba(42, 72, 156, 1) 100%
          );
          border-radius: 4px;
          border: none;
        }
      }
    }
  }
</style>
